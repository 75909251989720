// How we work   HeroBlock
.container {
  margin: 0 auto;
  width: 85%;
}
.page-how-we-work .hero-block{
  position: relative;
  margin-bottom: 80px;
  background-color: black;
  color: #f6f6f6;
  .content{
    position: relative;
    margin: 0 auto;
    padding: 130px 0 110px;
    font-family: 'HelveticaNeueLT_iTechArt', Arial, sans-serif;
    text-align: center;
    max-width: 70%;
    width: 100%;
    box-sizing: border-box;
    z-index: 0;
    h1{
      color: white;
      font-size: 60px;
      line-height: 60px;
      font-weight: bold;
      margin: auto;
    }
    .summary{
      font-size: 23px;
      margin-top: 30px;
    }
    .button-outline-red {
      display: inline-block;
      position: relative;
      padding: 9px 50px 12px;
      margin: 25px auto;
      border: 2px solid #ff0110;
      border-radius: 37px;
      color: white;
      font-size: 18px;
      width: fit-content;
      transition: 0.2s ease-in-out;
      &:hover::after {
        opacity: 1;
      }
      &::after{
        content: "";
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(to right,#f41351,#eb1c23);
        top: -2px;
        left: -2px;
        border-radius: 37px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }
    @media (max-width: 815px) {
      .summary{font-size: 17px;}
      h1{font-size: 37px;}
    }
    @media (min-width: 768px) and (max-width: 1023px){
      .summary{font-size: 20px; margin-top: 40px; line-height: 27px;}
      h1{font-size: 55px;}
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      .summary{font-size: 23px; margin-top: 40px;}
      h1{font-size: 60px; line-height: 60px;}
    }
    @media (min-width: 1440px) and (max-width: 1599px) {
      .summary{font-size: 23px;}
      h1{font-size: 70px; line-height: 70px;}
    }
    @media (min-width: 1600px) {
      .summary{font-size: 30px; margin-top: 40px;}
      h1{font-size: 90px; line-height: 90px;}
    }
  }
}

//How we work MainBlock
.page-how-we-work .main-block{
  margin-bottom: 100px;
  h2{
    font-size: 60px;
    display: inline-block;
    span{
      display: block;
      &:after{
        content: '';
        height: 7px;
        width: 130px;
        background-color: black;
        display: inline-block;
        margin-left: 3px;
        @media (max-width:815px){display: none;}
      }
    }
  }
  .blockitem{
    // transition: 0.5s ease-in-out;
    margin-top: 50px;
    padding-left: 6%;
    .head{
      font-size: 45px;
      display: block;
      margin-bottom: 20px;
      text-align: left;
      .title{
        font-weight: bold;
        display: block;
      }
      .semititle{
        color: red;
      }
    }
    .head+span{
      display: block;
      font-weight: 300;
      font-size: 25px;
      line-height: 1.1;
      width: 95%;
    }
  }
  @media (max-width: 815px) {
    h2{font-size: 50px;}
  }
}

// How we work   step-block
.page-how-we-work .step-block{
  margin-bottom: 100px;
  h2{
    font-size: 60px;
    display: inline-block;
  }
  h2>span{
    display: block;
  }
  h2+span{
    margin: 30px auto;
    display: block;
    font-size: 28px;
    text-align: justify;
  }
  .head{
    display: block;
    margin: auto;
    font-size: 65px;
    text-align: center;
    @media (max-width : 815px){font-size: 40px;}
  }
  .text{
    display: block;
    font-size: 28px;
    margin: 17px auto 0 auto;
    width: 100%;
    text-align: center;
    line-height: 1.3;
    @media (max-width : 815px){font-size: 17px;}
  }
  .step-chooser{
    .ant-tabs-nav{
      margin: auto;
      width: 75%;
    }
    .chooser-img{ width: 100%;}
    .ant-tabs-nav-operations{display: none !important;}
    .ant-tabs-nav-list{ width: 100%;}
    .ant-tabs-tab{
      width: 25%;
      margin: 0 0 0 0 !important;
    }
    .ant-tabs-nav::before{
      border-color: black !important;
      border-bottom-width: 2px !important;
    }
  }
  @media (max-width: 815px) {
    h2{font-size: 50px;}
    h2+span{font-size: 17px;}
  }
}


// How we work   We make your way

.page-how-we-work .your-way{
  padding-bottom: 60px;
  background-color: black;
  font-size: 25px;
  color: white;
  text-align: center;
  margin-bottom: 100px;
  h3{
    color: white;
    font-size: 45px;
    font-weight: 400;
    padding: 44px 0;
  }
  h3+span{
    display: block;
    margin: 0 auto;
    width: 85%;
  }
  .vertical-chooser{
    margin-top: 50px !important;
    margin-bottom: 100px !important;
        .ant-tabs-nav-operations{display: none !important;}
    .ant-tabs-nav-list{height: 100%;}
    .ant-tabs-tab{flex:1; margin: 0 0 0 0 !important; }
    .ant-tabs-nav{ width: 50%;}
    .ant-tabs-content-holder{ width:50%;}
    .ant-tabs-nav-wrap{ width: 100%;}
    .ant-tabs-ink-bar{background-color: red !important;}
    .ant-tabs-tab-btn{color: white; font-size: 30px;}
    .ant-tabs-tab-btn:active{color: red;}
    //.ant-tabs-tab-btn .ant-tabs-tab-btn:active{color: red; font-weight: inherit !important;}
    .chooser-img{
      width: 100%;
      height: 100%;
    }
  }

  .vertical-chooser .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: red !important ;
    font-weight: inherit !important;
  }

  .vertical-collapse{
    background-color: transparent !important;
    display: block;
    width: 88%;
    margin: 30px auto;
    .album{
      width: 70%;
      img{
        width: 100%;
      }
    }
    .ant-collapse-header{
      color: inherit;
      text-align: left;
      font-size: 30px;
      @media (max-width: 600px){font-size:17px;}
    }
    .ant-collapse-item{
      color: white;
      border: none !important;
    }
    .ant-collapse-item:hover{
      color:red;
    }
    .ant-collapse-item-active .ant-collapse-header{
      color: red !important;
    }
  }
  @media (max-width:815px){
    h3{font-size: 35px; line-height: 1.3; font-weight: 500;}
    h3+span{text-align: left;}
    font-size: 17px;
  }
}

// How we work    Perk with Team



.page-how-we-work .perk-with-team{
  
  h2{
    font-size: 60px;
    display: inline-block;
  }
  h2>span{
    display: block;
  }
  h2+span{
    margin: 30px auto;
    display: block;
    font-size: 28px;
    text-align: justify;
  }
  .list-flexbox{
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item{
      display: flex;
      flex-flow: nowrap;
      text-align: left;
      justify-content: space-between;
      border-bottom: 1px solid #6a6a6b;
      padding-bottom: 40px;
      margin-top: 55px;
      width: 100%;
      &>span:first-child{
        font-size: 45px;
        font-weight: 500;
        color: #000000;
        transform: translateY(-10px);
      }
      &>span:last-child{
        width: 60%;
        font-size: 21px;
        color: #6a6a6b;
      }
      @media (max-width:815px){
        &{display: block;}
        &>span:first-child{
          display: block;
          font-size: 28px;
        }
      }
    }
    &:last-child{
      border: none !important;
    }
  }
  @media (max-width:815px){
    h2{font-size: 40px; line-height: 1.3; font-weight: bold;}
    h2+span{text-align: left; font-size: 19px;}
  }
}

// How we work     Contact Us/ h2
.page-how-we-work .contact-form{
  .head-text{
    font-size: 90px !important;
    @media (max-width:650px){font-size: 50px !important;}
  }
}