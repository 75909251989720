.page-home {
  .home-text {
    position: fixed;
    bottom: 100px;
    margin-left: 70px;
    @media only screen and (max-width:768px) {
      margin: 0;
      left: 10%;
    }

    h1 {
      font-size: 50px;
      color: white;
      margin-bottom: 0;
      font-weight: bold;
    }
    h2 {
      font-size: 30px;
      color: white;
      margin-bottom: 0;
    }
    h3 {
      font-size: 16px;
      color: rgb(221, 124, 12);
      margin-bottom: 0;
    }
  }
}