.auth-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  .auth-form-button {
    width: 100%;
    margin-top: 10px;
  }
  
  .form-field-icon {
    color: rgba(0,0,0,.25);
  }
}
