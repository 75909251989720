.nav-sider {
  .side-menu {
    position: fixed;
    width: 200px;
  }
}

.page-admin-developers {
  padding: 20px;

  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  .create-button {
    float: right;
    margin-bottom: 10px;
  }

  .table-developers {
    border: 1px solid #e7e7e7;
  }
}

.page-admin-developer-profile {
  /*  Images  --------------------------------------------------------- */
  img {
    max-width: 100%;
    height: auto;
    &.pull-right { margin: 12px 0px 0px 18px; }
    &.pull-left { margin: 12px 18px 0px 0px; }
  }

  /* ------------------------------------------------------------------ */
  /* About Section
  /* ------------------------------------------------------------------ */
  #about {
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 40px;
    
    .avatar-layout {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 100%;
    }

    .name {
      margin-top: 10px;
    }

    .input-name {
      margin-top: 10px;
      width: 120px;
      text-align: center;
    }

    .input-overview {
      margin-top: 10px;
    }

    .hourlyrate-container {
      margin-top: 10px;
      align-items: center;
    }

    .input-hourlyrate {
      width: 60px;
      margin-left: 5px;
      margin-right: 5px;
      text-align: center;
    }
  }

  /* ------------------------------------------------------------------ */
  /* Resume Section
  /* ------------------------------------------------------------------ */
  #resume {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 72px;
    overflow: hidden;

    a, a:visited { color: #0762f9; }
    a:hover, a:focus { color: #313131; }

    .work, .education, .employment, .skills {
      margin-bottom: 48px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E8E8E8;
    }

    .workhistory_star {
      color: @primary-color;
    }

    .date {
      font: 15px/24px 'opensans-regular', sans-serif;
      color: #6E7881;
      margin-top: 6px;
      margin-bottom: 10px;
    }

    .info {
      font: 16px/24px 'librebaskerville-italic', serif;
      color: #6E7881;
      margin-bottom: 18px;
      margin-top: 9px;
    }

    .skill_item {
      border-bottom-right-radius: 30%;
      margin-top: 10px;
    }
  }

  /* ------------------------------------------------------------------ */
  /* e. Portfolio Section
  /* ------------------------------------------------------------------ */

  #portfolio {
    background: #ebeeee;
    padding-top: 50px;
    padding-bottom: 60px;

    .columns { margin-bottom: 36px; }

    .portfolio-item .item-wrap {
      background: #fff;
      overflow: hidden;
      position: relative;
    
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .portfolio-item .item-wrap a {
      display: block;
      cursor: pointer;
    }
    
    /* overlay */
    .portfolio-item .item-wrap .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
  
      opacity: 0;
      -moz-opacity: 0;
      filter:alpha(opacity=0);
  
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
  
//      background: url(/assets/images/profile/overlay-bg.png) repeat;
    }

    .portfolio-item .item-wrap .link-icon {
      display: block;
      color: #fff;
      height: 30px;
      width: 30px;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
  
      opacity: 0;
      -moz-opacity: 0;
      filter:alpha(opacity=0);
  
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
  
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
    }

    .portfolio-item .item-wrap img {
      vertical-align: bottom;
    }
      
    .portfolio-item .portfolio-item-meta { padding: 18px }
    .portfolio-item .portfolio-item-meta h5 {
      font: 14px/21px 'opensans-bold', sans-serif;
      color: #fff;
    }

    .portfolio-item .portfolio-item-meta p {
      font: 12px/18px 'opensans-light', sans-serif;
      color: #c6c7c7;
      margin-bottom: 0;
    }
    
    /* on hover */
    .portfolio-item:hover .overlay {
      opacity: 1;
      -moz-opacity: 1;
      filter:alpha(opacity=100);
    }

    .portfolio-item:hover .link-icon {
      opacity: 1;
      -moz-opacity: 1;
      filter:alpha(opacity=100);
    }
  }
}