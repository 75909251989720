.right-menu {
  width: 350px;
  right: 0;
  z-index: 20;
  position: fixed;
  height: 100%;
  top: 0;

  @media only screen and (min-width: 1024px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
  //  width: 100%;
  }
  .menu-background {
    transform-origin: left top;
    display: block;
    width: 100%;
    height: 120%;
    background-color: black;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .menu-navigation {
    visibility: inherit;
    opacity: 1;
    position: absolute;
    top: 75px;
    right: 50px;
    width: 250px;
    line-height: 40px;
    .menu-list {
      padding: 0;
      list-style-type: none;
      
      @media only screen and (min-width: 768px) {
        .secondary {
          display: none;
        }
      }
      li {
        text-align: right;
        visibility: inherit;
        opacity: 1;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
      a {
        display: block;
        color: white;
        border-bottom: 1pt solid #252525;
      }
      a:hover {
        color: #8b939b;
      }
    }
    
  }
}
