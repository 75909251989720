.page-browse-developers {
  padding-top: 50px;
  padding-bottom: 50px;

  .title-filter{
    padding: 0 16px 16px 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
  }

  .browse-developers-content {
    padding: 50px 0 0;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }

  .list-item-developer {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    cursor: pointer;

    &:hover {
      background: rgba(0,0,0,0.1);
    }

    .ant-row {
      width: 100%;
    }

    .title-name {
      color: @success-color;
    }

    .row-title {
      font-weight: bold;
    }
  }

  .pagination-container {
    padding: 30px;
    border-top: 1px solid #d9d9d9;
  }
}