.page-about-us {
  overflow: hidden;

  // mixins
  .w100 {
    width:100%;
  }

  .h100 {
    height:100%;
  }

  .full {
    .w100();
    height: 100%;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .oh {
    overflow: hidden;
  }

  .posr {
    position: relative;
  }

  .nowrap {
    white-space: nowrap;
  }

  .z-index-100 {
    z-index: 1;
    position: relative;
  }

  .margin-minus-30 {
    margin-top: -30px;
  }

  .margin-bottom-50 {
    margin-bottom: 50px;
  }

  .no-margins {
    margin: 0;
    padding: 0;
  }

  .text-center {
    text-align: center;
  }

  .left {
    text-align: left;
  }

  .hcenter {
    display: flex;
    align-items: center;
  }

  .d-flex {
    display: flex;
    // flex-flow:row wrap;
  }

  .flex-1 {
    flex : 1
  }

  .flex-2 {
    flex : 2
  }

  .flex-3 {
    flex : 3
  }

  .thin {
    font-weight: 200;
  }

  span.text-center {
    display: block;
  }

  h3 {
    margin: 0.4em 0;
    font-size: 2.1em;
  }

  h2 {
    margin: 0;
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    display: inline-block;
  }

  .container{
    margin: 0 auto;
    & > .data {
      @media only screen and (max-width: 815px) {
        margin-bottom: 50px;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .js-slide-up {
    opacity: 0;
    transform: translateY(25px);
    transition: all 0.7s ease-out;
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 1439px) and (min-width: 1024px) {
    *:focus, *:active, *:hover {
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
  }

  @media only screen and (max-width: 815px) {
    .article h1, .article h2, .article h3, .article h4, .article p, .article ul, .article ol {
      padding: 0 20px;
    }
    .nowrap {
      white-space: normal;
    }
    h2 {
      font-size: 40px;
      line-height: 1.15;
    }
    p {
      margin: 30px 0 30px 0;
      font-size: 17px;
      letter-spacing: normal;
      word-spacing: normal;
      line-height: 1.3;
    }
  }

  // pie-chart
  .pie-chart-block {
    @media only screen and (max-width: 815px) {
      .w100();
      flex-flow: column;
      margin-top: -50px;
      padding-top: 50px;
      background-color: #f2f3f4;
      padding-left: 0;
    }
    @media only screen and (max-width: 1450px) {
      padding-left: 10%;
      max-width: 90%;
    }
    @media only screen and (max-width: 1400px) {
      padding-left: 0;
      max-width: 100%;
    }
  }

  /* hero-block */
  .page .hero-block {
    color: #f6f6f6;
    margin-bottom: 80px;
    position: relative;
    .image {
      position: absolute;
      height: 100%;
      max-width: 100%;
      min-width: 100%;
      background: #000;
      overflow: hidden;
      z-index: 0;
    }
    .content {
      position: relative;
      margin: 0 auto;
      padding: 185px 0 110px;
      z-index: 1;
      text-align: center;
      max-width: 1100px;
      .w100();
      box-sizing: border-box;
      @media (max-width: 1600px) and (min-width: 1440px) {
        max-width: 850px;
      }
      @media (min-width: 1440px) {
        max-width: 850px;
      }
      @media (max-width: 1439px) and (min-width: 1024px) {
        max-width: 750px;
      }
      @media (min-width: 1024px) {
        .center();
        flex-direction: column;
        min-height: 750px;
      }
      @media (max-width: 1023px) and (min-width: 768px) {
        max-width: 650px;
        padding: 260px 0 165px;
      }
      h1 {
        color:white;
        font-size: 37px;
        line-height: 37px;
        font-weight: bold;
        @media (min-width: 1440px) {
          font-size: 70px;
          line-height: 70px;
        }
        @media (max-width: 1600px) and (min-width: 1440px) {
          font-size: 70px;
          line-height: 70px;
        }
        @media (max-width: 1439px) and (min-width: 1024px) {
          font-size: 60px;
          line-height: 60px;
        }
        @media (max-width: 1023px) and (min-width: 768px) {
          font-size: 55px;
          line-height: 55px;
        }
      }
      .summary {
        font-size: 17px;
        margin-top: 30px;
        line-height: 1.3;
        @media (min-width: 1440px) {
          font-size: 23px;
        }
        @media (max-width: 1600px) and (min-width: 1440px) {
          font-size: 23px;
        }
        @media (max-width: 1439px) and (min-width: 1024px) {
          margin-top: 15px;
          font-size: 23px;
        }
        @media (max-width: 1023px) and (min-width: 768px) {
          margin-top: 40px;
          font-size: 20px;
          line-height: 27px;
        }
      }
    }
  }


  /* bullet */

  .container > .data {
    display: block;
    margin-bottom: 100px;
    &.bulletproof {
      h2{
        color:black;
      }
    }
  }

  p {
    margin: 35px 0 45px 0;
    font-size: 25px;
    line-height: 1.3;
  }

  /* about-map */

  .page .black {
    display: block;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    // padding: 26px 0 75px 0;
    padding-top: 26px;
    padding-bottom: 75px;
    line-height: 1.3;
    font-size: 25px;
    margin-bottom: 100px;
    &.invert {
      background-color: #ffffff;
      color: #000000;
      padding-bottom: 0;
      position: relative;
    }
    &.recognized .flexbox .item img {
      filter: invert(1);
    }
    .container >.data, .container-1440>.data {
      margin-bottom: 0;
    }
    h2 {
      color:white;
      margin: 34px 0;
      &.left {
        display: block;
        text-align: left;
      }
    }
    h3 {
      font-size: 45px;
      font-weight: 400;
      margin: 34px 0;
      color: white;
    }
    &.thin {
      font-weight: 200;
    }
  }

  .map-about-us-container {
    .w100();
    height: 500px;
    margin-top: 80px;
  }

  #map_about_us {
    .w100();
    height: 100%;
    display: block;
  }

  /* software */

  .container > .data {
    display: block;
    margin-bottom: 100px;
  }


  /* Technology */

  /* left transition */
  .plist > .ant-col {
    padding-left: 16px;
    padding-right: 10px;
    padding-top: 5px;
    position: relative;
    overflow:hidden;
    max-height: 100%;
    line-height: 0.8;
    * {
      left: -120%;
      position: relative;
    }
    &.show {
      &:nth-child(2) > * {
        transition-delay: 1s;
      }
      &:nth-child(3) > * {
        transition-delay: 1.7s;
      }
      *{
        left: 0;
        transition: left 0.3s ease-out;
        transition-delay: 0.3s;
      }
      &:before {
        max-height: 100%;
        top: 0;
      }
      &:nth-child(2):before {
        transition-delay: 0.7s;
      }
      &:nth-child(3):before {
        transition-delay: 1.4s;
      }
    }
    &:before {
      content: "";
      width: 2px;
      height: 100%;
      background-color: #000000;
      max-height: 0;
      position: absolute;
      left: 0;
      top: 120%;
      transition: all 0.2s linear;
    }
  }

  .ul_padding {
    transition: all .3s ease;
    @media (max-width: 576px) {
      display: none;
    }
  }

  .techitems {
    display: flex;
    // flex-flow: nowrap;
    // .w100();
    h4 {
      font-size: 45px;
      margin-bottom: 15px;
      color: black;
    }
    span {
      line-height: 1px;
      font-size: 100%;
      font:inherit;
    }
    h2 {
      color:black;
    }
    .plist {
      margin-top: 30px;
      text-align: left;
      line-height: 0.7;
    }
  }

  h2.history {
    color:black;
    text-align: center;
    margin: 120px 0 70px 0;
    display: block;
  }

  .ant-slider-track, .ant-slider-handle {
    background-color: red!important;
  }


  .flexbox {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    @media only screen and (max-width: 1024px) and (min-width: 816px) {
      justify-content: space-around;
    }
    &.centered {
      justify-content: space-around;
      &.small-margin {
        flex: 1;
        margin: 0 9%;
      }
    }
    &.small-margin {
      flex: 1;
      margin: 0 9%;
    }
    &.basis-18 .item {
    flex-basis: 18%;
    }
    .item {
      padding-bottom: 15px;
      text-align: center;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
  }

  .makeitem {
    width: 90%;
    margin: 10px auto;
    padding: 0px 30px;
    text-align: center;
    :hover {
      color:red;
      img {
        filter: brightness(35%) sepia(100%) hue-rotate(-50deg) saturate(500%) contrast(2)!important;
      }
    }
    div {
      .center()
    }
  }

  .double-underline-block > div {
    text-align: left;
    /* font-size: 25px; */
    &:first-child {
      border-bottom: 2px solid #000;
      padding-bottom: 50px;
      margin-bottom: 50px;
    }
    &.text>div {
      width: 45%;
    }
    &.text {
      display: flex;
      flex-flow: nowrap;
      justify-content: space-between;
      & > div {
        width: 90%;
        margin: 10px auto;
        padding: 0px 30px;
        text-align: center;
      }
    }
    .head {
      font-size: 35px;
      margin-bottom: 30px;
    }
    .head + div {
      font-size: 17px;
    }
  }

  .page > .featured-works {
    margin-top: 49px;
    padding-top: 49px;
    display: block;
    text-align: center;
    .feature-slider {
      display: flex;
      max-width: 100%;
      width: 82%;
      margin: -80px auto 0 auto;
      flex-flow: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      padding: 5px 10px;
      .feature-item {
        .w100();
        height: 300px;
        display: flex;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
        &:hover .text > a
        {
          transform:translateY(0);
          cursor:pointer
        }
        &:first-child {
          width: 39.5%;
          margin-bottom: 76px;
          height: 378px;
        }
        &:nth-child(2) {
          width: 24.5%;
          height: 395px;
          // margin-bottom: -58px;
        }
        &:last-child {
          width: 32.5%;
          height: 625px;
        }
        
        img {
          max-width: 100%;
          max-height: 100%;
          .w100();
          height: 100%;
          position: absolute;
          left: 0;
          z-index: 0;
        }
        picture {
          max-width: 100%;
          max-height: 100%;
          .w100();
          height: 100%;
          position: absolute;
          z-index: 0;
        }
        .text {
          display: block;
          padding-left: 24px;
          text-align: left;
          overflow: hidden;
          margin-bottom: 28px;
          z-index: 1;
          position: relative;
          .w100();
          .project-name {
            font-size: 45px;
            line-height: 1.8;
            display: block;
            font-weight: 100;
            @media only screen and (max-width: 500px) {
              font-size: 35px;
            }
          }
          .project-tags {
            position: relative;
            display: inline-block;
            padding-right: 65px;
            & > span {
              @media only screen and (max-width: 1700px) {
                font-size: 21px;
              }
              display: block;
              font-size: 25px;
            }
            .tags_arrow {
              position: absolute;
              // border: 1px solid #000000;
              // border-radius: 41px;
              cursor: pointer;
              line-height: 0;
              right: 10px;
              top: 50%;
              transform: translateY(-100%);
              margin: 0;
              padding: 7px 4px;
              img {
                max-width: 20px;
                filter: brightness(0);
              }
            }
          }
          a {
            display: block;
            transform: translateY(45%);
            will-change: transform;
            transition: transform 0.2s linear;
            padding-bottom: 15px;
            @media (max-width: 1024px) {
              transform: translateY(0);
            }
          }
        }
      }
    }
    h2 {
      font-size: 90px;
      text-align: left;
      line-height: 1.1;
      transform: translateX(-28%);
      color: black;

      @media only screen and (max-width: 815px) {
          font-size: 55px;
          line-height: 1.2;
          transform: none;
          padding: 0 25px;
      }

      &.line > span:after {
        margin-left: 20px;
        content: "";
        height: 7px;
        width: 117px;
        background-color: #000;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    .feature-item{
      height: 355px!important;
      width: 290px!important;
      overflow: hidden!important;
      opacity: 1!important;
    }
    .slick-slide > div{
      .center();
    }
    .featured-works h2 {
      margin-bottom: 100px;
    }
  }

  @media (max-width: 478px) {
    .swiper-wrapper {
      height: 460px!important;
    }
  }
  .related-posts.related-articles .line>span:after {
    content: "";
    height: 7px;
    width: 117px;
    background-color: #000;
    display: inline-block;
    margin-left: 10px;
  }

  h2  > span {
    display: block;
  }

  .article a {
    color: inherit;
    border-color: inherit;
  }

  a {
    text-decoration: none;
    color: #eb1c23;
    outline: none;
  }

  .ant-slider {
    margin: 10px 20px!important;
    width: 1000px;
    transition: all .3s ease;
    @media(min-width: 1200px) {
      transform: none!important;
    }
  }

  .ant-slider-handle {
    border: solid 2px #ff0000!important;
    visibility: hidden;
  }

  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: #ff0000!important;
  }

  .ant-slider-dot {
    background-color: #ff0000!important;
    border: 2px solid #ff0000!important;
    height: 15px!important;
    width: 15px!important;
  }

  .ant-slider-dot-active {
    border-color: #ff0000!important;
    transform: scale(2, 2);
  }

  .ant-slider-rail, .ant-slider-track {
    height: 10px!important;
  }

  .ant-slider-mark-text {
    margin: -50px 0px;
    font-size: 22px;
  }

  .ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.85);
  }

  .our_history {
    padding-top: 100px;
  }

  .swiper-wrapper {
    height: 300px;
    position: relative;
    .w100();
    // height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    margin: 40px 0px;
  }

  .swiper-slider {
    position: absolute;
    .w100();
    height: 100%;
    padding: 0% 5%;
    display: flex;
    opacity: 0;
    transition: all .3s ease;
    &.show {
      opacity: 1;
    }
    @media (max-width: 768px) {
      .flex-2 {
        border-left: 0px!important;
        border-top: 2px solid black;
      }
      flex-direction: column;
    }
  }

  .recharts-pie-label-line {
    visibility: hidden;
  }

  .recharts-surface {
    width: 400px!important;
  }

  .our_history .swiper-slide {
    div {
      width: 50%;
      
      &:last-child {
        font-size: 18px;
        /* padding-left: 10%; */
        border-left: 2px solid #000000;
        /* width: 20%; */
        /* display: flex; */
        flex: 1 0 0;
        flex-flow: nowrap;
        justify-content: center;
        align-items: self-end;
        div {
          img {
            max-height: 100%;
            object-fit: contain;
            width: 90px;
          }
          display: flex;
          justify-content: center;
          flex-flow: column;
          margin-left: 60px;
        }
      }
    }
    @media (max-width: 1440px) {
      flex-direction: column!important;
      & > .ant-col {
        &:nth-child(1) {
          border-bottom: 2px solid black!important;
        }
        &:nth-child(2) {
          border-left: 0px!important;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .small_images > div.text{
      flex-direction: column;
    }
  }
}

