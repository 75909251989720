.newslist {
  max-width: 800px;
  margin: auto;
  
  .newslistbreadcrumb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid darkgray;

    .newslisttitle {
      margin-left: 12px;

      h3 {
        color: rgb(107 107 107);
        font-weight: 500;
      }
    }
  }
}

.newsview {
  margin-top: 7px;
  max-width: 800px;
  margin: auto;
  background-color: rgba(234, 238, 243, 1);

  .newsviewimg {
    background-color: darkgray;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 350px;
  }
  .newsview-content {
    margin: 20px;
    .newsviewtitlesection {
      margin-bottom: 20px;

      .newsviewdetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }  
      .newsviewtitle {
        h1 {
          font-weight: bold;
          margin-bottom: 6px;
        }
      }  
    }
    .newsviewbody {
      font-size: large;
    }
    .newsviewbody {
      clear: left;
      font-size: 16px;
      line-height: 1.58;
      letter-spacing: -0.003em;
    }
  }
}

.newscard {
  /*background-color: white;*/
  padding: 8px;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;*/
  border-radius: 4px;
  margin: 8px;
  cursor: pointer;
  display: flex;
  background-color: rgba(234, 238, 243, 1);

  .newscardimg {
    width: 146px;
    height: 146px;
    background-color: darkgray;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 9px;
    
  }

  .newscardtitle {
    flex: 1 100%;
  }
  .newscardtitle h1 {
    margin-top: 0;
    margin-bottom: 1px;
  }
  
}
